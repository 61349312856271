// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select_optionClass__P2Kcs {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
}

.select_circle__WIXtD {
  width: 13px;
  height: 13px;
  border: 3px solid #c7c7c7;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/select-box/select.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,YAAA;AACF;;AACA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;AAEF","sourcesContent":[".optionClass {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  height: 40px;\n}\n.circle {\n  width: 13px;\n  height: 13px;\n  border: 3px solid #c7c7c7;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionClass": `select_optionClass__P2Kcs`,
	"circle": `select_circle__WIXtD`
};
export default ___CSS_LOADER_EXPORT___;
