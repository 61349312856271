import React from 'react';
import style from './card.module.scss';
import pdfImage from '../../assets/pdf.png';

const Card = ({ heading, children, className, pdfUrl }) => {
  
  const handleDownloadPdf = () => {
    if (pdfUrl) {
      window.open(pdfUrl, '_blank');
    } else {
      alert('No PDF available to download.');
    }
  };
  

  return (
    <div className={`${className || ''} ${style.card1}`}>
      <h3 className={style.cardHeading}>{heading}</h3>
      <div
        className={`${style.cardChild}  ${pdfUrl ? style.cardChildPdf : ''}`}
      >
        {pdfUrl ? (
          <>
            <div className={style.iframe}>
              <iframe
                src={`${pdfUrl}`}
                frameBorder="0"
                title="PDF"
                className={style.iframe}
              />
            </div>
            <div className={style.pdf_icon} onClick={handleDownloadPdf}>
              <img src={pdfImage} alt="pdfIcon" />
            </div>
          </>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default Card;
