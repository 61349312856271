const baseURL = process.env.REACT_APP_BACKEND_DOMAIN;

export const getProductByLanguage = async (productId, language) => {
  const token = localStorage.getItem('token');

  try {
    const res = await fetch(
      `${baseURL}/api/products/product-detail/${productId}/${language}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          // Authorization: token || "",
        },
      }
    );

    if (!res.ok) {
      console.log(`Error fetching product: ${res.statusText}`);
    }

    const data = await res.json();
    return data;
  } catch (error) {
    console.error('Error getting Product:', error);
    throw error;
  }
};
