import React from "react";
import { default as ReactSelect, components } from "react-select";
import style from "./select.module.scss";

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div className={style.optionClass}>
        <div className={style.circle} />
        <label>{props.label}</label>
      </div>
    </components.Option>
  );
};

const CustomSelect = ({ options, placeholder, onChange, value }) => {
  return (
    <ReactSelect
      options={options}
      closeMenuOnSelect={true}
      hideSelectedOptions={false}
      components={{ Option: CustomOption }}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          border: "none",
          width: "250px",
          boxShadow: "0px 4px 4px 0px #0000000d",
          padding: "15px 11px 15px 21px",
        }),
        option: (baseStyles, { isSelected, isFocused }) => ({
          ...baseStyles,
          color: isSelected ? "#fff" : "#202020",
          fontSize: "20px",
          fontWeight: "500",
          display: "flex",
          justifyContent: "start",
          gap: "20px",
          backgroundColor: isSelected
            ? "#75b837"
            : isFocused
            ? "#e8e8e8"
            : "#fff",
          ":active": {
            ...baseStyles[":active"],
            backgroundColor: "#c7c7c7",
          },
        }),
      }}
    />
  );
};

export default CustomSelect;
