export const countries = [
  { label: "English", value: "uk_UK" },
  { label: "Francais", value: "fr_FR" },
  { label: "Espanol", value: "es_ES" },
  { label: "Italiano", value: "it_IT" },
  { label: "Deutsch", value: "de_DE" },
];
export const headings = [
  {
    dosage: "Usage and dosage",
    ingredients: "Ingredients",
    safetyDataSheet: "Safety Data Sheet",
    shopNow: "Shop Now",
    question: "Questions?",
    language: "uk_UK",
  },
  {
    dosage: "Application et dosage",
    ingredients: "Ingrédients",
    safetyDataSheet: "Fiche de données de sécurité",
    shopNow: "Acheter maintenant",
    question: "Des questions ?",
    language: "fr_FR",
  },
  {
    dosage: "Aplicación y dosificación",
    ingredients: "Ingredientes",
    safetyDataSheet: "Ficha de datos de seguridad",
    shopNow: "Comprar ahora",
    question: "¿Preguntas?",
    language: "es_ES",
  },
  {
    dosage: "Applicazione e dosaggio",
    ingredients: "Ingredienti",
    safetyDataSheet: "Scheda di sicurezza",
    shopNow: "Acquista ora",
    question: "Domande?",
    language: "it_IT",
  },
  {
    dosage: "Anwendung und Dosierung",
    ingredients: "Inhaltstoffe",
    safetyDataSheet: "Sicherheitsdatenblatt",
    shopNow: "Jetzt kaufen",
    question: "Fragen?",
    language: "de_DE",
  },
];
