import React, { useState, useEffect } from 'react';
import Card from '../../components/card/index.jsx';
import CustomSelect from '../../components/select-box/index.jsx';
import { headings, countries } from './helper.jsx';
import { getProductByLanguage } from '../../api/index';
import mainLogo from '../../../src/assets/logo.png';
import style from './main-page.module.scss';

const Bio = () => {
  const [productData, setProductData] = useState({});
  const [language, setLanguage] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);

  const getProductHandler = async (productId, language) => {
    const data = await getProductByLanguage(productId, language);

    console.log(data);
    setProductData(data?.productData || {});
    setDataLoaded(true);
  };

  const getHeadingText = (type) => {
    const selectedLanguage = headings.find(
      (item) => item.language === productData?.safetyDataSheet?.language
    );
    return selectedLanguage ? selectedLanguage[type] : '';
  };

  useEffect(() => {
    const pathParts = window.location.pathname.split('/');
    const productId = pathParts[3];
    const initialLanguage = pathParts[4];

    setLanguage(initialLanguage);
    getProductHandler(productId, initialLanguage);
  }, []);

  const handleLanguageChange = (selectedOption) => {
    const newLanguage = selectedOption.value;
    setLanguage(selectedOption);

    console.log(newLanguage);

    const pathParts = window.location.pathname.split('/');
    const productId = pathParts[3];

    window.history.pushState(
      {},
      '',
      `/products/products-detail/${productId}/${newLanguage}`
    );

    getProductHandler(productId, newLanguage);
  };


  return (
    <>
      <nav className={style.nav}>
        <div className={style.navInner}>
          <img
            src={mainLogo}
            alt="BIOHY"
            style={{ width: '143px', height: '35px' }}
          />
          <div className={style.rightNav}>
            <CustomSelect
              options={countries}
              onChange={handleLanguageChange}
              value={language}
              placeholder="Select"
            />
          </div>
        </div>
      </nav>
      {dataLoaded && productData.productName ? (
        <>
          <header className={style.header}>
            <div className={style.headerInner}>
              <div className={style.leftHeader}>{productData.productName}</div>
              <a
                href={productData.shopNowLink}
                target="_blank"
                rel="noreferrer"
              >
                <button className={style.headerBtn}>
                  {getHeadingText('shopNow')}
                </button>
              </a>
            </div>
          </header>
          <div className={style.mainWrapper}>
            <div className={style.cards}>
              <Card heading={getHeadingText('dosage')}>
                <p>{productData.dosage}</p>
              </Card>
              <Card heading={getHeadingText('ingredients')}>
                <p>{productData.ingredients}</p>
              </Card>

              <Card
                heading={getHeadingText('safetyDataSheet')}
                pdfUrl={productData.safetyDataSheet?.url}
              />
            </div>
          </div>
        </>
      ) : (
        <div className={style.noDataDiv}>
          No data found for the selected language.
        </div>
      )}
      <footer className={style.footer}>
        <div className={style.footerInner}>
          <h2 className={style.footerHeading}>
            {' '}
            {getHeadingText('question') || 'Questions?'}
          </h2>
          <p className={style.footerPara}>
            Melde Dich ganz einfach bei unserem Kundenservice. <br />
            01234 123456789 oder info@biohy-reiniger.de
          </p>
        </div>
      </footer>
    </>
  );
};

export default Bio;
